// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      CUSTOMIZE_LAYOUT: 'Customize layout',
      MANAGEMENT: 'Management',
      MEGA_MENU: 'Mega menu',
      TEMPLATE: 'Templates',
      SETTINGS: 'Settings',
      USER_MANAGEMENT: 'User Management',
      ROLE_MANAGEMENT: 'Role Management',
      USERS: 'Users',
      SYSTEM_USERS: 'System users',
      SYSTEM_ROLES: 'System roles',
      ROLES: 'Roles',
      PERMISSIONS: 'Permissions',
      ERRORS: 'Errors',
      WIDGETS: 'Widgets',
      ACCOUNTS: 'Accounts',
      WIZARDS: 'Wizards',
      CALENDAR: 'Calendar',
      HOME: 'Home',
      SYSTEM: 'System',
      CHANGE_PASSWORD: 'Change password',
      CUSTOMER: 'Customer',
      HEALTH_INFO: 'Health information',
      GYM_ACTIVITIES: 'Gym activities',
      GOALS: 'Goals',
      RUNNING: 'Running',
      BODYSCAN: 'Bodyscan',
      CHALLENGES: 'Challenges',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      LOGOUT: {
        BUTTON: 'Sign out',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        FORM_PASSWORD_TITLE: 'Change password',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        REQUIRED_KEYWORD: 'Required keyword...',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        PW_MIN_LENGTH: 'Password must be at least 6 characters',
        PW_MAX_LENGTH: 'Password should have maximum 50 characters',
        PW_REQUIRED: 'Password is required',
        CPW_MIN_LENGTH: 'Confirm Password must be at least 6 characters',
        CPW_MAX_LENGTH: 'Confirm Password should have maximum 50 characters',
        CPW_REQUIRED: 'Confirm Password is required',
        VALID_2PW: `'Passsword' and 'Confirm Password' didn't match.`,
        MIN_LENGTH_2: 'Field must be at least 2 characters',
        MIN_LENGTH_4: 'Field must be at least 4 characters',
        MIN_LENGTH_6: 'Field must be at least 6 characters',
        MIN_LENGTH_8: 'Field must be at least 8 characters',
        MIN_LENGTH_10: 'Field must be at least 10 characters',
        MAX_LENGTH_10: 'Field should have maximum 10 characters',
        MAX_LENGTH_20: 'Field should have maximum 20 characters',
        MAX_LENGTH_50: 'Field should have maximum 50 characters',
        MAX_LENGTH_100: 'Field should have maximum 100 characters',
        MAX_LENGTH_250: 'Field should have maximum 250 characters',
        REQUIRED_SELECT: 'You must be chose an option',
        PHONE_INVALID: 'Phone number invalid',
        LANG_KEY_INVALID: 'Lang key invalid',
      },
      NOTIFY: {
        CONFIRM: 'Are you sure?',
        DESC_CONFIRM: 'Do you really want to delete this item? This process cannot be undone.',
      },
      PERMISSION: {
        CREATE: 'Create',
        EDIT: 'Edit',
        DELETE: 'Delete',
        EXPORT: 'Export',
        ADVANCE_SEARCH: 'Advance search',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    PAGINATOR: {
      ITEM_PER_PAGE: 'Items per page',
      TOTAL_PAGES: 'Total pages',
      TOTAL_ITEMS: 'Total items:',
      FIRST: 'First',
      PREVIOUS: 'Previous',
      NEXT: 'Next',
      LAST: 'Last',
    },
    BUTTON: {
      REFRESH: 'Refresh',
      SORT: 'Sort',
      CREATE: 'Create',
      SEARCH: 'Search',
      APPLY: 'Apply',
      SUBMIT: 'Submit',
      CANCEL: 'Cancel',
      OK: 'OK, I got it!',
      DISCARD: 'Discard',
      DELETE: 'Delete',
      EXPAND: 'Expand',
      COLLAPSE: 'Collapse',
      FILTER: 'Filter',
      CLEAR_FILTER: 'Clear Filter',
      EXPORT: 'Exports',
      TODAY: 'Today',
      CHANGE_PASSWORD: 'Update password',
      UPDATE: 'Update',
      ADD_RESOURCE: 'Add Role resource',
      CLOSE: 'Close',
      SAVE: 'Save',
    },
    LABEL: {
      HIDE_SHOW_COLUMN: 'Show/Hide Columns',
      CLEAR_SORT: 'Clear Sort',
      LATEST: 'Latest',
      NAME_SORT_A2Z: 'Name sort by A-Z',
      NAME_SORT_Z2A: 'Name sort by Z-A',
      ID_SORT_12N: 'ID sort by 1-n',
      ID_SORT_N21: 'ID sort by n-1',
      DAY: 'Day',
      WEEK: 'Week',
      MONTH: 'Month',
      YEAR: 'Year',
      D: 'D',
      W: 'W',
      M: 'M',
      Y: 'Y',
      ADD_MORE: 'Add more other calendars',
      SELECT: 'Select',
      AVATAR: 'Avatar',
      ROLE: 'Role',
      PASSWORD: 'Password',
      ACTION: 'Action',
      ID: 'ID',
      CREATED_DATE: 'Created date',
      CREATED_BY: 'Created by',
      UPDATED_DATE: 'Updated date',
      UPDATED_BY: 'Updated by',
      DELETED_DATE: 'Deleted date',
      STATUS: 'Status',
      GROUP_ID: 'Group ID',
      ACCOUNT: 'Account',
      USER_ACCOUNT: 'User account',
      EMAIL: 'Email',
      USER_EMAIL: 'User email',
      FULLNAME: 'Fullname',
      USER_FULLNAME: 'User fullname',
      ADDRESS: 'User address',
      USER_ADDRESS: 'User address',
      PHONE: 'Phone',
      PHONE_CODE: 'Phone country code',
      USER_PHONE: 'User phone',
      BIRTHDAY: 'Birthday',
      USER_BIRTHDAY: 'User birthday',
      LASTED_LOGIN: 'Lasted login',
      USER_LASTED_LOGIN: 'User lasted login',
      LOGED_IN_TIME: 'Logged In Times',
      USER_LOGIN_COUNT: 'User login count',
      SECURE_CODE: 'Secure code',
      USER_SECURE_CODE: 'User secure code',
      SEARCH_BY: 'Search by',
      SEARCH_FIELD: 'Search Field',
      ORDER_FIELD: 'Order Field',
      ORDER_TYPE: 'Sortt Type',
      ASC: 'ASC',
      DESC: 'DESC',
      OTHER: 'other',
      OTHERS: 'others',
      ALL: 'All',
      GROUP1: '1',
      GROUP2: '2',
      ROLE1: 'Role 1',
      ROLE2: 'Role 2',
      NOT_SET: 'Not set',
      NEW_PASSWORD: 'New Password',
      C_NEW_PASSWORD: 'Confirm New Password',
      GROUP: 'Group',
      AREA: 'Area',
      PROVINCE: 'Province',
      DISTRICT: 'District',
      WARD: 'Ward',
      ROLE_VALUE: 'Role value',
      ROLE_NAME: 'Role name',
      ROLE_PARENT_ID: 'Role parent ID',
      POINTS: 'Points',
      HEIGHT: 'Height',
      WEIGHT: 'Weight',
      DISTANCE: 'Distance',
      STEPS: 'Steps',
      BURNNED: 'Burnned',
      CAL_WILL_BURN: 'Desired calorie burn',
      TOTAL_BURNNED: 'Total calories burned: ',
      MEDICINE_EFFECT: 'Medicine Effect',
      BLOODTYPE: 'Blood type',
      EMPTY: 'Empty',
      HEARTRATE: 'Heartrate',
      INTENSITY: 'Exercise intensity',
      UPDATE_TIME: 'Update at: ',
      EXERCISE_TIME: 'Exercise time: ',
      NORMAL_WORKOUT: 'Normal workout',
      TABATA_WORKOUT: 'Tabata workout',
      NORMAL: 'Normal: ',
      MAX: 'Max: ',
      AVERAGE: 'Average: ',
      HEALTH_DATE: 'Health update in ',
      INITIAL_WEIGHT: 'Initial weight: ',
      AVAILABLE_GOAL: 'Available',
      UN_AVAILABLE_GOAL: 'Un available',
      CHALLENGE_TITLE: 'Challenge title: ',
      START_CHALLENGE: 'Start at: ',
      FINISHED_CHALLENGE: 'Finished at: ',
      LANG_LOCALE: 'Lang locale',
      LANG_KEY: 'Lang key',
      LANG_VALUE: 'Lang value',
      SCAN_ID: 'Scan ID',
      AGE: 'Age',
      GENDER: 'Gander',
      SCAN_DATE: 'Scan Date',
    },
    UNIT: {
      CM: 'cm',
      KG: 'Kg',
      KM: 'Km',
      M: 'm',
      S: 's',
      BPM: 'Bpm',
      SECONDS: 'Seconds',
      MIN: 'Min',
      CAL: 'Cal',
      KCAL: 'Kcal',
      RATE: '%',
      POINT: 'Points',
      STEP: 'Steps',
    },
    PLACEHOLDER: {
      ACCOUNT: '',
      LANG_LOCAL: '',
      LANG_LOCAL_EX: 'Ex: ja, vn, en,...',
      LANG_KEY: '',
      LANG_KEY_EX: 'Ex: role_name',
      LANG_VALUE: '',
      LANG_VALUE_EX: 'Ex: Admin',
    },
    STATUS: {
      LOADING: 'Loading...',
      E404: 'Error 404',
      E500: 'Error 500',
      ALL: 'All',
      ACTIVE: 'Active',
      PENDING: 'Pending',
      DEACTIVE: 'Deactive',
      NO_DATA_RESULT: 'No data matching the filter ',
      NO_DATA_GYM: 'No activity',
      NO_DATA_HEALTH: 'No health information',
      NOT_STARTED: 'Not started',
      JOINNING: 'Joinning',
      CLAIMING: 'Claiming',
      FINISHED: 'Finished',
      NO_LANGUAGE_ROLE: 'No language declared for role',
    },
    WIDGET: {
      LISTS: 'Lists',
      STATISTICS: 'Statitics',
      CHARTS: 'Charts',
      MIXED: 'Mixed',
      TABLES: 'Tables',
      FEED: 'Feeds',
    },
    ACCOUNT: {
      OVERVIEW: 'Overview',
      PROFILE: 'Profile',
      PROJETCS: 'Projects',
      CAMPAIGN: 'Campaigns',
      DOCUMENT: 'Document',
      CONNECTION: 'Connections',
    },
    WIZARD: {
      HORIZON: 'Horizontal',
      VERTICAL: 'Vertical',
    },
    MODAL: {
      SYTEM_USER: {
        TITLE_CREATE: 'Create a System user',
        TITLE_UPDATE: 'Update System user',
      },
      SYTEM_ROLE: {
        TITLE_CREATE: 'Create a System role',
        TITLE_UPDATE: 'Update System role',
      },
      CUSTOMER: {
        TITLE: 'Customer profile',
      },
    },
  },
};
