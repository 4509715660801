import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IResponse } from 'src/app/base/base.interface';
import { SystemAuth, SystemUser } from 'src/app/domains/models';
import { SystemUserDto } from 'src/app/domains/dto/system-user.dto';

const API_USERS_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  // cache

  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<SystemAuth | undefined> {
    return this.http
      .post<IResponse<SystemAuth>>(`${API_USERS_URL}/auth/user/login`, {
        username: email,
        password,
      })
      .pipe(map(response => response.data));
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: SystemUserDto): Observable<SystemUserDto> {
    return this.http.post<SystemUserDto>(`${API_USERS_URL}/auth/user/signup`, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  //change password
  changePassword(password: string) {
    return this.http.post<IResponse<unknown>>(`${API_USERS_URL}/auth/user/change-password`, {
      password,
    });
  }

  getUserByToken(): Observable<SystemUser | undefined> {
    return this.http
      .get<IResponse<SystemUser>>(`${API_USERS_URL}/auth/user/profile`)
      .pipe(map(response => response.data));
  }
}
