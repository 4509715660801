import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PermissionService } from './permission.service';
import { SystemAuth, SystemUser } from 'src/app/domains/models';
import { IResponse } from 'src/app/base/base.interface';

export type SystemUserModel = SystemUser | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authSessionStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<SystemUser | undefined>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<SystemUser | undefined>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): SystemUser | undefined {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: SystemUser | undefined) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private permissionService: PermissionService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<SystemUser | undefined>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // public methods
  login(username: string, password: string): Observable<SystemUser | undefined> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(username, password ? password : '').pipe(
      map((auth: SystemAuth | undefined) => {
        if (auth) {
          const result = this.setAuthFromSessionStorage(auth);
          return result;
        } else return false;
      }),
      switchMap(() => this.getUserByToken()),
      catchError(err => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    sessionStorage.removeItem(this.authSessionStorageToken);
    sessionStorage.removeItem('system-user-profile');
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<SystemUser | undefined> {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    const profile = this.getProfileFromSessionStorage();
    if (profile) {
      this.currentUserSubject.next(profile);
      this.permissionService.currentValue = profile.authorities?.map(item => Number(item)) ?? [];
      this.isLoadingSubject.next(false);
      return of(profile);
    }

    return this.authHttpService.getUserByToken().pipe(
      map((user: SystemUser | undefined) => {
        if (user) {
          this.currentUserSubject.next(user);
          this.setProfileFromSessionStorage(user);
          this.permissionService.currentValue = user.authorities?.map(item => Number(item)) ?? [];
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePassword(password: string): Observable<IResponse<unknown>> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePassword(password)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromSessionStorage(auth: SystemAuth): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      sessionStorage.setItem(this.authSessionStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromSessionStorage(): SystemAuth | undefined {
    try {
      const lsValue = sessionStorage.getItem(this.authSessionStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private getProfileFromSessionStorage(): SystemUser | undefined {
    try {
      const profileValue = sessionStorage.getItem('system-user-profile');
      if (!profileValue) {
        return undefined;
      }

      return JSON.parse(profileValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private setProfileFromSessionStorage(profile: SystemUser): void {
    sessionStorage.setItem('system-user-profile', JSON.stringify(profile));
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
