import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  currentPermitSubject: BehaviorSubject<Array<string | number>>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor() {
    this.currentPermitSubject = new BehaviorSubject<Array<string | number>>([]);
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
  }

  get currentValue(): Array<string | number> {
    return this.currentPermitSubject.value;
  }

  set currentValue(permit: Array<string | number>) {
    this.currentPermitSubject.next(permit);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
