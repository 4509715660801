// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v8.2.3',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  appThemeName: 'Skeleton CMS',
  apiUrl: 'https://dev.apis.fulfit.jp/apis/v1',
  secretKey: 'base64:dpOLBCBQJ5uw5QU+GPmc9wWsk8kO9MOj7IeCkabaTfM=',
  deviceUuid: '123456',
  deviceKey: 'Kbi8bqX9es09uOYnLN19UR9YCr9kpcE3',
};

export const permission = {
  DASHBOARD: 1001,
  DASHBOARD_RECENT_CHART: 100101,
  DASHBOARD_RECENT_CHART_WEEK: 10010101,
  DASHBOARD_RECENT_CHART_MONTH: 10010102,
  DASHBOARD_RECENT_CHART_YEAR: 10010103,
  DASHBOARD_NEW_USERS: 100102,
  DASHBOARD_RECENT_ORDER: 100103,
  MANAGEMENT: 1002,
  MANAGEMENT_SYSTEM_USERS: 100201,
  MANAGEMENT_SYSTEM_USERS_CREATE: 10020101,
  MANAGEMENT_SYSTEM_USERS_EDIT: 10020102,
  MANAGEMENT_SYSTEM_USERS_DELETE: 10020103,
  MANAGEMENT_SYSTEM_USERS_EXPORTS: 10020104,
  MANAGEMENT_SYSTEM_USERS_ADVANCE_SEARCH: 10020105,
  MANAGEMENT_SYSTEM_ROLES: 100202,
  MANAGEMENT_SYSTEM_ROLES_CREATE: 10020201,
  MANAGEMENT_SYSTEM_ROLES_EDIT: 10020202,
  MANAGEMENT_SYSTEM_ROLES_DELETE: 10020203,
  MANAGEMENT_SYSTEM_ROLES_EXPORTS: 10020204,
  MANAGEMENT_SYSTEM_ROLES_ADVANCE_SEARCH: 10020205,
  MANAGEMENT_CUSTOMER: 100203,
  MANAGEMENT_CUSTOMER_CREATE: 10020301,
  MANAGEMENT_CUSTOMER_EDIT: 10020302,
  MANAGEMENT_CUSTOMER_DELETE: 10020303,
  MANAGEMENT_CUSTOMER_EXPORTS: 10020304,
  MANAGEMENT_CUSTOMER_ADVANCE_SEARCH: 10020305,
  CALENDAR: 1004,
  CUSTOMIZE: 1005,
  CUSTOMIZE_SIDEBAR: 100501,
  CUSTOMIZE_HEADER: 100502,
  CUSTOMIZE_TOOLBAR: 100503,
  CUSTOMIZE_THEME: 100504,
  MANAGEMENT_SYSTEM_MEDIA: 100601,
  MANAGEMENT_SYSTEM_MEDIA_CREATE: 10060101,
  MANAGEMENT_SYSTEM_MEDIA_EDIT: 10060102,
  MANAGEMENT_SYSTEM_MEDIA_DELETE: 10060103,
  MANAGEMENT_SYSTEM_MEDIA_EXPORTS: 10060104,
  MANAGEMENT_SYSTEM_MEDIA_ADVANCE_SEARCH: 10060105,
};

export type PermissionType = {
  key: number;
  label: string;
  checked: boolean;
  children?: PermissionType[];
};

export const permissionTreeMap: PermissionType[] = [
  {
    key: 1001,
    checked: false,
    label: 'MENU.DASHBOARD',
    children: [
      {
        key: 100101,
        checked: false,
        label: 'Chart',
        children: [
          { key: 10010101, checked: false, label: 'View data by week' },
          { key: 10010102, checked: false, label: 'View data by month' },
          { key: 10010103, checked: false, label: 'View data by year' },
        ],
      },
      {
        key: 100102,
        checked: false,
        label: 'The number of new users',
      },
      {
        key: 100103,
        checked: false,
        label: 'Recent oders',
      },
    ],
  },
  {
    key: 1002,
    checked: false,
    label: 'MENU.USER_MANAGEMENT',
    children: [
      {
        key: 100201,
        checked: false,
        label: 'MENU.SYSTEM_USERS',
        children: [
          { key: 10020101, checked: false, label: 'AUTH.PERMISSION.CREATE' },
          { key: 10020102, checked: false, label: 'AUTH.PERMISSION.EDIT' },
          { key: 10020103, checked: false, label: 'AUTH.PERMISSION.DELETE' },
          { key: 10020104, checked: false, label: 'AUTH.PERMISSION.EXPORT' },
          { key: 10020105, checked: false, label: 'AUTH.PERMISSION.ADVANCE_SEARCH' },
        ],
      },
      {
        key: 100202,
        checked: false,
        label: 'MENU.CUSTOMER',
        children: [
          { key: 10020201, checked: false, label: 'AUTH.PERMISSION.CREATE' },
          { key: 10020202, checked: false, label: 'AUTH.PERMISSION.EDIT' },
          { key: 10020203, checked: false, label: 'AUTH.PERMISSION.DELETE' },
          { key: 10020204, checked: false, label: 'AUTH.PERMISSION.EXPORT' },
          { key: 10020205, checked: false, label: 'AUTH.PERMISSION.ADVANCE_SEARCH' },
        ],
      },
    ],
  },
  {
    key: 1003,
    checked: false,
    label: 'MENU.ROLE_MANAGEMENT',
    children: [
      {
        key: 100302,
        checked: false,
        label: 'MENU.SYSTEM_ROLES',
        children: [
          { key: 10030201, checked: false, label: 'AUTH.PERMISSION.CREATE' },
          { key: 10030202, checked: false, label: 'AUTH.PERMISSION.EDIT' },
          { key: 10030203, checked: false, label: 'AUTH.PERMISSION.DELETE' },
          { key: 10030204, checked: false, label: 'AUTH.PERMISSION.EXPORT' },
          { key: 10030205, checked: false, label: 'AUTH.PERMISSION.ADVANCE_SEARCH' },
        ],
      },
    ],
  },
  {
    key: 1004,
    checked: false,
    label: 'MENU.CALENDAR',
  },
  {
    key: 1005,
    checked: false,
    label: 'MENU.CUSTOMIZE_LAYOUT',
    children: [
      {
        key: 100501,
        checked: false,
        label: 'Sidebar settings',
      },
      {
        key: 100502,
        checked: false,
        label: 'Header settings',
      },
      {
        key: 100503,
        checked: false,
        label: 'Toolbar settings',
      },
      {
        key: 100504,
        checked: false,
        label: 'Theme settings',
      },
    ],
  },
  {
    key: 1006,
    checked: false,
    label: 'MENU.MEDIA_MANAGEMENT',
    children: [
      {
        key: 100602,
        checked: false,
        label: 'MENU.SYSTEM_MEDIA',
        children: [
          { key: 10060201, checked: false, label: 'AUTH.PERMISSION.CREATE' },
          { key: 10060202, checked: false, label: 'AUTH.PERMISSION.EDIT' },
          { key: 10060203, checked: false, label: 'AUTH.PERMISSION.DELETE' },
          { key: 10060204, checked: false, label: 'AUTH.PERMISSION.EXPORT' },
          { key: 10060205, checked: false, label: 'AUTH.PERMISSION.ADVANCE_SEARCH' },
        ],
      },
    ],
  },
];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
