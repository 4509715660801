import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authSessionStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = sessionStorage.getItem(this.authSessionStorageToken);
    const lang = localStorage.getItem('language');

    let headers = {
      DeviceUuid: environment.deviceUuid,
      DeviceKey: environment.deviceKey,
      Authorization: '',
      'x-lang': 'jp',
    };

    if (authToken) {
      const authData = JSON.parse(authToken);
      headers.Authorization = authData.accessToken;
    }

    if (lang) headers['x-lang'] = lang;

    const modifiedReq = req.clone({
      setHeaders: headers,
    });
    return next.handle(modifiedReq);
  }
}
